@keyframes scaleBox {
  from {
    transform: scale(0.4, 0.4);
  }
  to {
    transform: scale(1, 1);
  }
}

@keyframes scaleHeight {
  from {
    transform: scale(1, 0.4);
  }
  to {
    transform: scale(1, 1);
  }
}

@keyframes scaleWidth {
  from {
    transform: scale(0.5, 1);
  }
  to {
    transform: scale(1, 1);
  }
}

.popup {
  animation-name: scaleBox;
  animation-duration: 150ms;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
.popupSlideUp {
  animation: slideUp 0.5s ease forwards;
}

.expand {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* transition-duration: 150ms; */
  animation-name: scaleHeight;
  animation-duration: 150ms;
}

.expand-horizontal {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* transition-duration: 150ms; */
  animation-name: scaleWidth;
  animation-duration: 250ms;
}

.up-to-down {
  animation: upToDown 500ms ease-in-out forwards;
}

@keyframes upToDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking {
  animation: blink 900ms infinite;
}
