ul {
  list-style-type: none;
  margin: 0;
  padding: 10;
  overflow: hidden;
  background-color: #333e49;
}

li {
  float: right;
}

li a {
  display: block;
  color: white;
  text-align: center;
  padding: 20px 23px;
  text-decoration: none;
}

li a:hover:not(.active) {
  background-color: #111;
}

.active {
  background-color: #04aa6d;
}

.box::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5); /* Apply box shadow */
  z-index: -10; /* Set z-index lower than the main box */
}
