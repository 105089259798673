* {
  font-family: "Roboto";
}
.outfit {
  font-family: "Outfit" !important;
}
.heading-gradient {
  color: white;
  size: 1.25rem;
  background: linear-gradient(180deg, #397b8a 0%, #065668 88.11%);
}

.top-shadow {
  box-shadow: 0px -2px 15px 0px #00000040;
}

.home-banner {
  background: linear-gradient(180deg, #f8feffd4 0%, #4bc2de 100%);
}

.home-mobile {
  background: linear-gradient(180deg, #f8feffd4 0%, #ffffff13 100%);
}

.averia {
  font-family: Averia Serif Libre;
  letter-spacing: 0.04em;
}
.averialoc {
  font-family: Averia Serif Libre;
  font-weight: 400;
  line-height: 44.78px;
  text-align: center;
}
.scada {
  font-family: Scada;
  font-weight: 400;
  line-height: 44.78px;
  text-align: center;
}

.scadaSmall {
  font-family: Scada;
}
.single {
  font-family: Single Day;
  font-weight: 400;
  line-height: 45px;
  text-align: center;
}

.secular {
  font-family: Secular One;
  font-weight: 400;
  line-height: 52.38px;
  text-align: center;
}
